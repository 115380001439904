import React, {Component, useEffect, useState} from 'react';
import styles from './Escalations.module.css';
import statStyles from '../StatPage/StatPage.module.css';
import {processTimeRange} from "../../utils/utils";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {MobileDateTimePicker} from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
function Escalations() {
    const [timeRange, setTimeRange] = useState(["Last 24h", "Today", "Yesterday", "This week", "Last week", "This month", "Last month", "This year"]);
    const [selectedTimeRange, setSelectedTimeRange] = useState("Last 24h");
    const [statsStart, setStatsStart] = useState(new Date(Date.now() - 24 * 60 * 60 * 1000));
    const [statsEnd, setStatsEnd] = useState(new Date(Date.now()));
    const [paginationSize, setPaginationSize] = useState(10)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date) => {
        return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
    }
    useEffect(() => {
        processTimeRange(selectedTimeRange, setStatsStart, setStatsEnd)
    }, [selectedTimeRange]);

    const onPageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    }



        return (
            <>
             <div className={styles.escalationPage}>
                 <div className={statStyles.controlBar}>
                     <div className={`${statStyles.buttonBlock} ${statStyles.timeRangeBlock}`}>
                         <button className={statStyles.button}>{selectedTimeRange}</button>
                         <ul className={`${statStyles.dropdownBlock} ${statStyles.timeRangeDropdownBlock}`}>
                             {
                                 timeRange.map(range => (
                                     <li className={statStyles.dropdownItem}
                                         onClick={() => setSelectedTimeRange(range)}>{range}</li>
                                 ))
                             }
                         </ul>
                     </div>
                     <div className={`${statStyles.buttonBlock} ${statStyles.dateForm}`}>
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <MobileDateTimePicker value={dayjs(statsStart)} onChange={(e) => {
                                 const changedDate = formatDate(e);
                                 setStatsStart(new Date(changedDate))
                             }}
                                                   ampm={false}
                                                   inputFormat="YYYY-MM-DD HH:mm"
                             />
                         </LocalizationProvider>
                     </div>
                     <div className={`${statStyles.buttonBlock} ${statStyles.dateForm}`}>
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                             <MobileDateTimePicker value={dayjs(statsEnd)} onChange={(e) => {
                                 const changedDate = formatDate(e);
                                 setStatsEnd(new Date(changedDate))
                             }}
                                                   ampm={false}
                                                   inputFormat="YYYY-MM-DD HH:mm"
                             />
                         </LocalizationProvider>
                     </div>
                     <div className={`${statStyles.buttonBlock}`}>
                         <button className={`${statStyles.button} ${statStyles.reportButton}`}>
                             Get Report
                         </button>
                     </div>
                 </div>
                <div className={styles.escalationTable}>
                    <div className={styles.tableCell}>
                        Logged
                    </div>
                    <div className={styles.tableCell}>
                        Service
                    </div>
                    <div className={styles.tableCell}>
                        History
                    </div>
                    <div className={styles.tableCell}>
                        Causes
                    </div>
                    <div className={styles.tableCell}>
                        Prevent
                    </div>
                </div>
                <div className={styles.paginationBock}>
                    <div className={styles.paginationSizeBlock}>
                        <div className={styles.paginationSize}>
                            {paginationSize}
                        </div>
                        <ul className={styles.paginationDropDown}>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(10)}>10</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(20)}>20</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(30)}>30</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(40)}>40</li>
                            <li className={styles.paginationDropDownItem} onClick={() => setPaginationSize(50)}>50</li>
                        </ul>
                    </div>
                    <p className={styles.paginationSizeText}>Record per page</p>
                    <p className={styles.totalRecordsText}> Showing from {(currentPage * paginationSize) - paginationSize + 1} to {(currentPage * paginationSize)} from {totalRecords} records</p>
                    <ul className={styles.paginationPagesBlock}>
                        <li className={`${styles.paginationPageButtonCover} ${styles.paginationPageButtonFirst}`}>
                            <button className={styles.paginationPageButton}
                                    onClick={() => onPageClick(currentPage - 1)}
                                    disabled={currentPage < 2}
                            />
                        </li>
                        {
                            currentPage > 3 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton}
                                            onClick={() => onPageClick(1)}>
                                        1
                                    </button>
                                     </li>
                                :
                                null
                        }
                        {
                            currentPage > 4 ?
                                <li className={styles.paginationPageButtonCover}> ... </li>
                                :
                                null
                        }
                        {
                            currentPage > 2 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button
                                        className={styles.paginationPageButton}
                                        onClick={() => onPageClick(currentPage - 2)}
                                       >
                                        {currentPage - 2}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            currentPage > 1 ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button
                                        className={styles.paginationPageButton}
                                        onClick={() => onPageClick(currentPage - 1)}>
                                        {currentPage - 1}
                                    </button>
                                </li>
                                :
                                null
                        }
                        <li className={`${styles.paginationPageButtonCover}  ${styles.paginationPageButtonCoverActive}`}>
                            <button className={`${styles.paginationPageButton} ${styles.paginationPageButtonActive}`}>
                                {currentPage}
                            </button>
                        </li>
                        {
                            (Math.floor(totalRecords / paginationSize) ) >= (currentPage + 1) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton} onClick={() => onPageClick(currentPage + 1)}>
                                        {currentPage + 1}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            (Math.floor(totalRecords / paginationSize) ) >= (currentPage + 2) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton} onClick={() => onPageClick(currentPage + 2)}>
                                        {currentPage + 2}
                                    </button>
                                </li>
                                :
                                null
                        }
                        {
                            (Math.floor(totalRecords / paginationSize) ) >= (currentPage + 4) ?
                                <li className={styles.paginationPageButtonCover}> ... </li>
                                :
                                null
                        }
                        {
                            (Math.floor(totalRecords / paginationSize) ) >= (currentPage + 3) ?
                                <li className={styles.paginationPageButtonCover}>
                                    <button className={styles.paginationPageButton}
                                            onClick={() => onPageClick(Math.floor(totalRecords / paginationSize))}>
                                        {  Math.floor(totalRecords / paginationSize)}
                                    </button>
                                </li>
                                :
                                null
                        }
                        <li className={`${styles.paginationPageButtonCover} ${styles.paginationPageButtonLast}`}>
                            <button className={styles.paginationPageButton}
                                    onClick={() => onPageClick(currentPage + 1)}
                                    disabled={currentPage >= Math.floor(totalRecords / paginationSize)}
                            />
                        </li>
                    </ul>
                </div>
             </div>
            </>
        );
}

export default Escalations;